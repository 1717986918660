<template>
    <section id="activities" class="py-5">
        <the-banner-small>Werking</the-banner-small>
        <div class="container my-3">
            <div class="row">
                <div class="col-12">
                    <h3>Werking...</h3>
                    <p>Van bij het start van de werking tot februari 2021, werden alle eetwaren door het Sint-Martinusfonds aangekocht bij de lokale magazijnen en winkels. Deze aankopen werden gefinancierd door:
                        <ul>
                            <li>Subsidie van het OCMW Berlare</li>
                            <li>Sponsoring door particulieren</li>
                            <li>Opbrengsten van activiteiten, ingericht door particulieren</li>
                            <li>Tussenkomsten door de parochies Berlare, Uitbergen en Overmere.</li>
                            <li>2-jaarlijke frituur Sint-Martinus</li>
                            <li>...</li>
                        </ul>
                    </p>
                    <p>
                        Het kostenplaatje van deze aankopen, nam stilaan grote(re) afmetingen aan omwille van het stijgend aantal minderbedeelden en de stijgende prijs van de meeste voedingswaren. Vandaar dat werd uitgekeken naar andere mogelijkheden om de voedselpakketten op niveau te kunnen houden.
                        Met de jaarwisseling (20/21) heeft het Sint-Martinusfonds een aanvraag tot toetreding ingediend bij de "Voedselbanken van Oost-Vlaanderen" en in februari 2021 werd onze aanvraag goedgekeurd. In maart 2021 kon het SMF dan voor de eerste keer genieten van de gratis droge voeding.
                        Het grote voordeel van deze aansluiting is dat het SMF nu kan genieten van een deel gratis voedingsproducten, wat merkelijk scheelt in het kostenplaatje, waardoor andere plannen, passend in het kader van het doel van het noodfonds, kunnen worden bekeken.
                    </p>
                </div>
                <div class="col-12">
                    <h3>... en samenwerking</h3>
                    <p>
                        Om deze hulp aan behoeftigen te kunnen realiseren, kunnen wij rekenen op de steun van het gemeentebestuur van Berlare, via het OCMW, waarbij wij kunnen getuigen van een zeer goede samenwerking. Jaarlijks ontvangen wij een subsidie om de werking van het SMF deels te ondersteunen.
                        Duidelijk dient gesteld dat het SMF geen deel uitmaakt van het OCMW.
                        Het is ook geen tweede OCMW, dit is totaal onmogelijk en is geenszins de bedoeling.
                        Het SMF wil wel zijn steentje bijdragen om de nood van arme(re) mensen te helpen lenigen, in tijden van onzekerheid, stijgende levensduurte, soms werkloosheid, relatiebreuk, ...
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import TheBannerSmall from '../components/layout/TheBannerSmall'

export default {
    components: {
        TheBannerSmall
    }
}
</script>

<style>

</style>
